import {
  apiGetGameInfo,
  apiPostPlay,
  apiGetPlayResult,
  apiGetTicket,
  apiPostLog,
} from '@/api/game';
import { oddDataToPlayData } from '@/utils/SportLib';
import { PanelModeEnum } from '@/config/bet';
import rootStore from '@/store';

const quickBetData = {
  isShow: false,
  x: 0,
  y: 0,
};

const lastFirstBetData = {
  // init : 未下注狀態
  // done : 可以開啟quickBet
  // lock : 無法開啟quickBet
  // wait : 下注中
  state: 'init',
  message: '',
};

export default {
  namespaced: true,
  state: {
    betCartList: [],
    panelMode: PanelModeEnum.normal,
    isAddNewToChart: false,
    isReCalcBetCart: false,
    isClearMemberData: false,
    isSubmitHandler: false,
    isShowStatusData: false,
    isShowStatusData2: false,
    isBetterOdds: false,
    isShowAcceptTip: false,
    tipAmount: 0,

    quickBetData,
    lastFirstBetData: Object.assign({}, lastFirstBetData),

    strayOdd: null,
    isShowStrayStatusData: false,

    tracingList: [],
    tracingCode: null,
    tracingBackup: {},
    isShowTracing: false,

    // 如果此值發生變化,ListCardItem,會去清除狀態
    triggerListCardItemClear: false,

    betHistoryList: [],
    betHistoryListCount: 0,
    unsettledBetCount: 0,
    flag: false,
  },
  getters: {
    showBetCartList(state) {
      return state.betCartList;
    },
    showBetHistoryList(state) {
      return state.betHistoryList;
    },
  },
  mutations: {
    setIsShowStatusData(state, val) {
      state.isShowStatusData = val;
    },
    setIsShowStatusData2(state, val) {
      state.isShowStatusData2 = val;
    },
    setIsReCalcBetCart(state, val) {
      state.isReCalcBetCart = val;
    },
    setIsShowStrayStatusData(state, val) {
      state.isShowStrayStatusData = val;
    },
    setBetCartList(state, val) {
      state.betCartList = val;
    },
    resetStatus(state) {
      state.isShowStatusData = false;
      state.isShowStatusData2 = false;
      state.isShowStrayStatusData = false;
      state.isBetterOdds = false;
      state.isShowAcceptTip = false;
      state.tipAmount = 0;
    },
    setLastFirstBetDataMessage(state, val) {
      state.lastFirstBetData.message = val;
    },
    setLastFirstBetDataState(state, val) {
      state.lastFirstBetData.state = val;
    },
    clearLastFirstBetData(state) {
      state.lastFirstBetData = Object.assign({}, lastFirstBetData);
    },
    showQuickBetData(state, { isShow, x, y }) {
      state.quickBetData.isShow = isShow;
      state.quickBetData.x = x;
      state.quickBetData.y = y;
    },
    setPanelMode(state, val) {
      state.panelMode = val;
    },
    setIsClearMemberData(state) {
      state.isClearMemberData = !state.isClearMemberData;
    },
    setIsSubmitHandler(state) {
      state.isSubmitHandler = !state.isSubmitHandler;
    },
    setStrayOdd(state, val) {
      state.strayOdd = val;
    },
    clearCart(state) {
      state.betCartList = [];
      state.strayOdd = null;
    },
    clearCartBetResult(state) {
      state.betCartList.forEach((it) => {
        it.betResult = null;
        it.betResultCount = 0;
        it.statusMessage = '';
        it.isShowMinText = false;
        it.isShowMaxText = false;
        it.isSuccessMode = false;
        it.isForceLock = false;
        it.hasDataChange = false;
      });
      state.quickBetData = quickBetData;
      state.triggerListCardItemClear = !state.triggerListCardItemClear;
    },
    removeCartByGameID(state, gameID) {
      const cartIndex = state.betCartList.findIndex((cartData) => cartData.GameID === gameID);
      if (cartIndex > -1) {
        state.betCartList.splice(cartIndex, 1);
      }
    },
    setBetHistoryList(state, list) {
      state.betHistoryList.length = 0;
      state.betHistoryList = list;
    },
    setBetHistoryListCount(state, count) {
      state.betHistoryListCount = count;
    },
    setUnsettledBetCount(state, count) {
      state.unsettledBetCount = count;
    },
    updateBetCartListDataChangeByGameID(state, gameID) {
      const findIndex = state.betCartList.findIndex((it) => it.GameID === gameID);
      if (findIndex !== -1) {
        state.betCartList[findIndex].hasDataChange = true;
      }
    },
    updateBetCartListOriginShowOddByGameID(state, { gameID, newShowOdd }) {
      const findIndex = state.betCartList.findIndex((it) => it.GameID === gameID);
      if (findIndex !== -1) {
        state.betCartList[findIndex].OriginShowOdd = newShowOdd;
      }
    },
    setTracingCode(state, code) {
      state.tracingCode = code;
    },
    setShowTracing(state, show) {
      state.isShowTracing = show;
    },
    addTracing(state, copy = false) {
      const code = state.tracingCode;
      if (code) {
        const item = {
          running: true,
          status: 0,
          title: window.appVue.$t('Common.Bet0'), // 投注中 / 確認中
          backup: state.tracingBackup[code],
          code,
        };
        state.tracingList.push(item);
        state.tracingCode = null;
        delete state.tracingBackup[code];
        if (copy) {
          const list = state.betCartList;
          state.betCartList = [];
          list.forEach((i) => state.betCartList.push(Object.assign({}, i)));
        }
      }
    },
    removeTracing(state, code) {
      const index = state.tracingList.findIndex((item) => item.code === code);
      if (index !== -1) {
        state.tracingList.splice(index, 1);
      }
    },
    backupTracing(state, item) {
      state.tracingBackup[item.traceCodeKey] = item;
    },
    unbackupTracing(state, key) {
      if (key in state.tracingBackup) {
        delete state.tracingBackup[key];
      }
    },
    showTracingItem(state, item) {
      state.isShowTracing = true;
      state.betCartList = item.backup.list;
      state.panelMode = PanelModeEnum.result;
    },

    triggerFlag(state) {
      state.flag = !state.flag;
    },
  },
  actions: {
    addCart({ rootState, state, commit, dispatch }, betData) {
      const { betCartList: carts } = state;
      const { GameID, GameTypeID, CatID, ModID, WagerTypeID, WagerGrpID, EvtID, EvtType } = betData;

      if (state.panelMode === PanelModeEnum.result) {
        commit('addTracing');
        commit('setShowTracing', false);
        commit('clearCart');
        commit('setPanelMode', PanelModeEnum.Normal);
        commit('setIsClearMemberData');
      }

      const cartIndex = carts.findIndex((cart) => cart.GameID === GameID);
      if (cartIndex !== -1) {
        const cart = carts[cartIndex];
        carts.splice(cartIndex, 1);
        if (cart.wagerPos === betData.wagerPos) return;
      }

      let betAmount = null;
      const { isQuickBet: quickBet } = rootStore.state.Game;
      const { defaultAmount } = rootStore.state.Setting.UserSetting;
      if (quickBet.isEnable) {
        betAmount = parseInt(quickBet.amount);
      } else if (defaultAmount.type === 1 || defaultAmount.type === 2) {
        betAmount = parseInt(defaultAmount.amount);
      }

      let PlayBetMax = null;
      let BetMin = null;
      let BetMax = null;
      let BetMaxEvt = null;
      let BetMaxPL = null;
      let EnableParlay = 1;
      let EvtMode = false;
      let BetMaxMShow = false;
      const { limits } = rootState.Game;
      let BetMax2 = null;
      let PlayBetMaxByGameToAPI = null;
      let APIMax = false;

      if (Array.isArray(limits)) {
        let item = null;

        // 球種限紅
        const newList0 = limits.filter(({ CatID: itemCatID }) => itemCatID === CatID);

        // 賽事限紅
        let newList1 = newList0.filter(({ EvtIDs }) => EvtIDs && EvtIDs.includes(EvtID));
        if (newList1.length > 0) {
          // 玩法限紅
          let newList2 = newList1.filter(
            ({ WagerTypeID: itemWagerTypeID }) => itemWagerTypeID === WagerTypeID
          );
          if (newList2.length === 0) {
            newList2 = newList1.filter(({ WagerTypeID: itemWagerTypeID }) => itemWagerTypeID === 0);
          }

          // EvtType 限紅
          let newList3 = newList2.filter(
            ({ EvtTypes }) =>
              (!EvtTypes.length && [0, 1, 2].includes(EvtType)) || EvtTypes.includes(EvtType)
          );
          if (!newList3.length) {
            newList3 = newList2.filter(({ EvtTypes }) => EvtTypes.includes(9));
          }

          // GameType 限紅
          let newList4 = newList3.filter(
            ({ GameType: itemGameType }) => itemGameType === GameTypeID
          );
          if (newList4.length === 0) {
            newList4 = newList3.filter(({ GameType: itemGameType }) => itemGameType === -1);
          }

          // InPlay 限紅
          let newList5 = null;
          if (GameTypeID === 2) {
            newList5 = newList4.filter(({ InPlay: itemInPlay }) => itemInPlay === 1);
          } else {
            newList5 = newList4.filter(({ InPlay: itemInPlay }) => itemInPlay === 0);
          }

          // WagerGrp 限紅
          let newList6 = newList5.filter(({ WagerGrpIDAry: itemWagerGrpIDAry }) =>
            itemWagerGrpIDAry.includes(WagerGrpID)
          );

          if (newList6.length === 0) {
            // 網球單節玩法 31, 32, 33, 34
            // 透過單節名稱識別, 文字變動時要配合調整
            if (['單節', '单节', 'Quarters', 'quarter'].includes(betData.ItemName)) {
              newList6 = newList5.filter(
                ({ WagerGrpIDAry }) =>
                  WagerGrpIDAry.includes(31) ||
                  WagerGrpIDAry.includes(32) ||
                  WagerGrpIDAry.includes(33) ||
                  WagerGrpIDAry.includes(34)
              );
            }
          }

          if (newList6.length > 0) {
            item = newList6[0];
            EvtMode = true;
          }
        }

        if (!item) {
          newList1 = newList0.filter(({ EvtIDs }) => !EvtIDs); // 玩法限紅

          let newList2 = newList1.filter(
            ({ WagerTypeID: itemWagerTypeID }) => itemWagerTypeID === WagerTypeID
          );
          if (newList2.length === 0) {
            newList2 = newList1.filter(({ WagerTypeID: itemWagerTypeID }) => itemWagerTypeID === 0);
          }

          // EvtType 限紅
          let newList3 = newList2.filter(
            ({ EvtTypes }) =>
              (!EvtTypes.length && [0, 1, 2].includes(EvtType)) || EvtTypes.includes(EvtType)
          );
          if (!newList3.length) {
            newList3 = newList2.filter(({ EvtTypes }) => EvtTypes.includes(9));
          }

          // GameType 限紅
          let newList4 = newList3.filter(
            ({ GameType: itemGameType }) => itemGameType === GameTypeID
          );
          if (newList4.length === 0) {
            newList4 = newList3.filter(({ GameType: itemGameType }) => itemGameType === -1);
          }

          // InPlay 限紅
          let newList5 = null;
          if (GameTypeID === 2) {
            newList5 = newList4.filter(({ InPlay: itemInPlay }) => itemInPlay === 1);
          } else {
            newList5 = newList4.filter(({ InPlay: itemInPlay }) => itemInPlay === 0);
          }

          // WagerGrp 限紅
          let newList6 = newList5.filter(({ WagerGrpIDAry: itemWagerGrpIDAry }) =>
            itemWagerGrpIDAry.includes(WagerGrpID)
          );

          if (newList6.length === 0) {
            // 網球單節玩法 31, 32, 33, 34
            // 透過單節名稱識別, 文字變動時要配合調整
            if (['單節', '单节', 'Quarters', 'quarter'].includes(betData.ItemName)) {
              newList6 = newList5.filter(
                ({ WagerGrpIDAry }) =>
                  WagerGrpIDAry.includes(31) ||
                  WagerGrpIDAry.includes(32) ||
                  WagerGrpIDAry.includes(33) ||
                  WagerGrpIDAry.includes(34)
              );
            }
          }
          if (newList6.length > 0) {
            item = newList6[0];
          }
        }

        if (item) {
          PlayBetMax = item.PlayBetMax; // 控端
          BetMin = item.BetMin;
          BetMax = item.BetMax; // 管端
          BetMaxEvt = item.BetMaxEvt; // 管端
          BetMaxPL = item.BetMaxPL;
          EnableParlay = item.EnableParlay;
          PlayBetMaxByGameToAPI = item.PlayBetMaxByGameToAPI;

          console.log(item);

          // EvtID 賽事限紅
          if (EvtMode) {
            const index = item.EvtIDs.indexOf(EvtID);
            if (index !== -1) {
              BetMax2 = PlayBetMax[index]; // 控端
              if ( BetMax2 >= BetMax ) { // 如果控端限紅大於管端限紅，取最小限紅(管端限紅)
                BetMax2 = BetMax
                BetMaxMShow = true;
              } else {
                BetMax = PlayBetMax[index];
              }
            }
          }
          // 槍數限紅
          else if (PlayBetMax && ModID < PlayBetMax.length) {
            BetMax2 = PlayBetMax[ModID]; // 控端
            if ( BetMax2 >= BetMax ) { // 如果控端限紅大於管端限紅，取最小限紅(管端限紅)
              BetMax2 = BetMax
              BetMaxMShow = true;
            } else {
              BetMax = PlayBetMax[ModID];
            }
          }

          // API 槍數限紅
          if (PlayBetMaxByGameToAPI.length > ModID && PlayBetMaxByGameToAPI[ModID]) {
            BetMaxEvt = PlayBetMaxByGameToAPI[ModID];
            APIMax = true;
            console.log('api modid', BetMaxEvt);
          }
          // API EvtID 賽事限紅
          if (EvtMode) {
            const index = item.EvtIDs.indexOf(EvtID);
            if (index !== -1) {
              if (PlayBetMaxByGameToAPI.length > index && PlayBetMaxByGameToAPI[index]) {
                BetMaxEvt = PlayBetMaxByGameToAPI[index];
                APIMax = true;
                console.log('api evtid', BetMaxEvt);
              }
            }
          }

          if (!Number.isFinite(BetMin)) {
            BetMin = 100;
          }
          if (!Number.isFinite(BetMax)) {
            BetMax = 500002;
          }
          if (!Number.isFinite(BetMaxEvt)) {
            BetMaxEvt = 500002;
          }
          if (!Number.isFinite(BetMaxPL)) {
            BetMaxPL = 500002;
          }
          if (!Number.isFinite(EnableParlay)) {
            EnableParlay = 1;
          }
          if (!BetMin) {
            BetMin = 100;
          }
          if (!BetMax) {
            BetMax = 500002;
          }
          if (!BetMaxEvt) {
            BetMaxEvt = 500002;
          }
          if (!BetMaxPL) {
            BetMaxPL = 500002;
          }
        } else {
          BetMin = 100;
          BetMax = 500003;
          BetMaxEvt = 500003;
          BetMaxPL = 500003;
          EnableParlay = 1;
        }
      } else {
        BetMin = 100;
        BetMax = 500003;
        BetMaxEvt = 500003;
        BetMaxPL = 500003;
        EnableParlay = 1;
      }

      if (betAmount < BetMin) {
        betAmount = BetMin;
      }

      const newCart = {
        HomeScore: undefined,
        AwayScore: undefined,
        ...betData,
        BetMin,
        BetMax,
        BetMax2,
        BetMaxEvt,
        BetMaxPL,
        BetMaxMShow,
        EnableParlay,
        betAmount,
        winAmount: null,
        betResult: null,
        betResultCount: 0,
        statusMessage: '',
        isShowMinText: false,
        isShowMaxText: false,
        isSuccessMode: false,
        isForceLock: false,
        hasDataChange: false,
        apiUpdated: false,
        APIMax,
      };
      newCart.playData = oddDataToPlayData(betData.SetFlag, WagerTypeID, newCart);

      carts.push(newCart);
      state.isAddNewToChart = !state.isAddNewToChart;

      dispatch('updateCartGames', [GameID]);
    },
    updateCartGames({ state, commit }, GameIDs) {
      if (state.panelMode === PanelModeEnum.result) return;

      return apiGetGameInfo(GameIDs)
        .then((response) => {
          if (state.panelMode === PanelModeEnum.result) return;

          const { betCartList: carts } = state;
          const { data: games } = response;
          games.forEach((game) => {
            const { GameID } = game;
            const cartIndex = carts.findIndex((cart) => cart.GameID === GameID);
            if (cartIndex !== -1) {
              const cart = carts[cartIndex];
              const newCart = (carts[cartIndex] = {
                ...cart,
                Status: game.Status,
                EvtStatus: game.EvtStatus,
                HdpPos: game.HdpPos,
                AwayHdp: game.AwayHdp,
                AwayHdpOdds: game.AwayHdpOdds,
                AwayOdds: game.AwayOdds,
                HomeHdp: game.HomeHdp,
                HomeHdpOdds: game.HomeHdpOdds,
                HomeOdds: game.HomeOdds,
                DrewOdds: game.DrewOdds,
                OULine: game.OULine,
                OverOdds: game.OverOdds,
                UnderOdds: game.UnderOdds,
                apiUpdated: true,
              });

              if (game.HomeScore || game.HomeScore === 0) {
                newCart.HomeScore = game.HomeScore;
              }
              if (game.AwayScore || game.AwayScore === 0) {
                newCart.AwayScore = game.AwayScore;
              }

              newCart.playData = oddDataToPlayData(newCart.SetFlag, newCart.WagerTypeID, newCart);
            }
          });

          state.betCartList = state.betCartList.slice();
          commit('setIsReCalcBetCart', !state.isReCalcBetCart);

          commit(
            'Game/updateGameList',
            {
              isUpdateFromOtherStore: true,
              updateData: games,
            },
            { root: true }
          );
          commit(
            'MoreGame/updateMoreGameData',
            {
              isUpdateFromOtherStore: true,
              updateData: games,
            },
            { root: true }
          );
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateAllCartGames({ state, dispatch }) {
      const { betCartList: carts } = state;
      if (carts.length > 0) {
        const gameIDs = carts.map((cart) => cart.GameID);
        dispatch('updateCartGames', gameIDs);
      }
    },
    submitCarts({ state, commit, dispatch }, { list, isStray = false, isQuick = false }) {
      commit('showLoading', null, { root: true });
      return apiPostPlay(list)
        .then((response) => {
          const { data } = response;
          const { Balance, TicketCount, traceCodeKey, ticket: results } = data;
          commit('setUserBalance', Balance, { root: true });
          commit('setUnsettledBetCount', TicketCount);
          commit('setPanelMode', PanelModeEnum.result);

          const has201 = results.some((result) => result.code === 201 || result.code === 202);
          if (has201) {
            commit('setTracingCode', traceCodeKey);
            commit('backupTracing', {
              quick: isQuick,
              type: isStray ? 99 : 1,
              index: isStray ? 1 : 0,
              list: state.betCartList,
              ...data,
            });

            if (isQuick) {
              commit('setLastFirstBetDataState', 'wait');
              commit('setLastFirstBetDataMessage', results[0]?.Message);
            } else {
              dispatch('updateTraceCartsResult', {
                results,
                traceCodeKey,
                isStray,
              });
            }

            setTimeout(() => {
              dispatch('traceCartsResult', { traceCodeKey, isStray, isQuick });
            }, 600);
          } else {
            if (isQuick) {
              commit('setLastFirstBetDataState', 'done');
              commit('setLastFirstBetDataMessage', results[0]?.Message);
            } else {
              dispatch('updateCartsResult', {
                results,
                isStray,
              });
            }
          }
        })
        .catch((error) => {
          console.error(error);
          commit('setPanelMode', PanelModeEnum.normal);
        })
        .finally(() => {
          commit('hideLoading', null, { root: true });
        });
    },
    traceCartsResult(
      { state, commit, dispatch },
      { traceCodeKey, isStray = false, isQuick = false }
    ) {
      return apiGetPlayResult(traceCodeKey)
        .then((response) => {
          const { Balance, TicketCount, data: results } = response;
          commit('setUserBalance', Balance, { root: true });
          commit('setUnsettledBetCount', TicketCount);

          const has201 = results.some((result) => result.code === 201 || result.code === 202);
          if (has201) {
            if (traceCodeKey === state.tracingCode) {
              if (isQuick) {
                commit('setLastFirstBetDataMessage', results[0]?.Message);
              } else {
                dispatch('updateTraceCartsResult', { results });
              }
            } else {
              const has202 = results.some((result) => result.code === 202);
              if (has202) {
                const item = state.tracingList.find((item) => item.code === traceCodeKey);
                if (item) {
                  item.title = '確認中';
                }
              }
            }
            setTimeout(() => {
              dispatch('traceCartsResult', { traceCodeKey, isStray, isQuick });
            }, 2000);
          } else {
            if (traceCodeKey === state.tracingCode) {
              state.tracingCode = null;
              commit('unbackupTracing', traceCodeKey);

              if (isQuick) {
                commit('setLastFirstBetDataState', 'done');
                commit('setLastFirstBetDataMessage', results[0]?.Message);
              } else {
                dispatch('updateCartsResult', {
                  results,
                  isStray,
                });
              }
            } else {
              const item = state.tracingList.find((item) => item.code === traceCodeKey);
              if (item) {
                item.running = false;
                item.data = results;
                const hasFail = results.some((result) => result.code < 0);
                if (hasFail) {
                  item.status = 2;
                  const hasSuccess = results.some((result) => result.code === 200);
                  if (hasSuccess) item.title = window.appVue.$t('Common.Bet1'); // '部分失敗';
                  else item.title = window.appVue.$t('Common.Bet2'); // '投注失敗';
                  const list = item.backup.list;
                  results.forEach((it, index) => {
                    if (it.code === 200) {
                      list[index].isSuccessMode = true;
                    } else if (it.code <= -2000 && it.code >= -2999) {
                      list[index].isForceLock = true;
                    }
                    list[index].statusMessage = it.Message;
                  });
                } else {
                  // test
                  // item.status = 4
                  // item.title = '測試';
                  //
                  item.status = 1;
                  item.title = window.appVue.$t('Common.Bet3'); // '投注成功';
                  commit('unbackupTracing', traceCodeKey);
                  setTimeout(() => {
                    commit('removeTracing', traceCodeKey);
                  }, 2000);
                }
                return;
              }
            }

            commit('triggerFlag');
          }
        })
        .catch((error) => {
          console.error(error);
          if (isQuick) {
            commit('setLastFirstBetDataState', 'done');
          }
        });
    },
    updateCartsResult({ state, commit, dispatch }, { results, isStray = false }) {
      let fails = 0;
      let hasChange = false;
      const { betCartList: carts } = state;
      results.forEach((result, index) => {
        const cart = carts[index];
        const { code } = result;
        if (code === 200) {
          cart.isSuccessMode = true;
          if (result.PayoutOdds) {
            state.isBetterOdds = true;
          }
        } else if (code < 0) {
          fails += 1;
          if (code <= -2000 && code > -3000) {
            cart.isForceLock = true;
          } else if (code <= -3000 && code > -4000) {
            hasChange = true;
          }
        }
        cart.betResult = result;
        cart.betResultCount += 1;
        cart.statusMessage = result.Message;
      });

      if (fails > 0) {
        commit('setPanelMode', PanelModeEnum.normal);
        if (isStray) {
          state.isShowStrayStatusData = true;
        } else {
          state.isShowStatusData = true;
          state.isReCalcBetCart = !state.isReCalcBetCart;
          state.tipAmount = fails;

          const show2 = results.find(
            (result) => result.code < 0 && (result.code >= -4000 || result.code < -4999)
          );
          commit('setIsShowStatusData2', show2);
        }

        if (hasChange) {
          state.isShowAcceptTip = true;

          setTimeout(() => {
            dispatch('updateAllCartGames');
          }, 2000);
        }
      }
    },
    updateTraceCartsResult({ state }, { results }) {
      const { betCartList: carts } = state;
      results.forEach((result, index) => {
        const cart = carts[index];
        cart.betResult = result;
        cart.betResultCount += 1;
        cart.statusMessage = result.Message;
      });
    },

    apiGetTicket(store, postData) {
      return new Promise((resolve, reject) => {
        return apiGetTicket(postData)
          .then((res) => {
            if (res.data?.list) {
              // 歷史注單
              store.commit(
                'setBetHistoryList',
                res.data.list.map((it) => {
                  return {
                    ...it,
                    isCollapse: false,
                  };
                })
              );
            }
            // 歷史注單數量
            if (!postData.isset) {
              store.commit('setBetHistoryListCount', res.data?.Count || 0);
            }
            resolve(res);
          })
          .catch(reject);
      });
    },
  },
};
